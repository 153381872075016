import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, FigureDouble, List,
} from '../../components/opComponents';

const DisjonctionAcromioClaviculaire = () => (
  <Layout
    title="Disjonction acromio-claviculaire : Diagnostic et traitement"
    description="La disjonction acromio-claviculaire est une perte de contact partielle (subluxation) ou totale (luxation) entre la clavicule et l'acromion."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-lepaule" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de l&apos;épaule</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Disjonction acromio-claviculaire" />
        <MainPart title="Qu'est-ce qu'une disjonction acromio-claviculaire ?">
          <Typography>
            Il s&apos;agit d&apos;une perte de contact partielle (subluxation)
            ou totale (luxation) entre la clavicule et l&apos;acromion.
          </Typography>
        </MainPart>

        <MainPart title="Comment s'articule la clavicule ? ">
          <Typography>
            La clavicule s&apos;articule avec le sternum (manubrium) médialement et avec
            la scapula (acromion) latéralement. Elle est située au-dessus du plexus
            brachial et des vaisseaux (artère et veine) sous-claviers (Figure 1).
          </Typography>
          <Figure imageName="figure1-disjonctionacromioclaviculaire.jpg" caption="Figure 1. Anatomie osseuse et ligamentaire de l&apos;épaule" currentOpTitle="Disjonction acromio claviculaire" />
          <Typography>
            <a className="underline text-link hover:text-primary" href="https://fr.wikipedia.org/wiki/Clavicule" target="_blank" rel="noreferrer">La clavicule</a>
            {' '}
            est stabilisée par les ligaments coraco-claviculaires
            (entre la coracoïde et la clavicule) et les ligaments acromio-claviculaires
            (entre l&apos;acromion et la clavicule).
          </Typography>
        </MainPart>

        <MainPart title="Quelles sont les causes possibles d'une disjonction acromio-claviculaire ?">
          <Typography>
            La disjonction acromio-claviculaire est fréquemment observée dans le cadre d&apos;un
            {' '}
            <Typography variant="span" weight="bold">
              traumatisme à haute énergie, après accident sportif
            </Typography>
            {' '}
            (rugby, ski, vélo...) ou sur la voie publique (voiture, moto...).
          </Typography>
        </MainPart>

        <MainPart title="Quels sont les symptômes d'une disjonction acromio-claviculaire ?">
          <List>
            <List.Item>
              <Typography>
                Douleurs en regard de l&apos;articulation
                acromio-claviculaire avec impossibilité bouger le bras
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome en regard de l&apos;articulation acromio-claviculaire
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Saillie de la clavicule associée à une chute du moignon de
                l&apos;épaule (ptose) sous l&apos;effet du poids du membre (Figure 2)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                « Touche de piano » : réduction possible de la clavicule
                en appuyant dessus tout en remontant le bras du patient
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Tiroir ou laxité antéro-postérieur de la clavicule secondaire
                aux lésions ligamentaires
              </Typography>
            </List.Item>
          </List>
          <Figure maxWidth="max-w-[400px]" imageName="figure2-disjonctionacromioclaviculaire.jpg" caption="Figure 2. Disjonction acromio-claviculaire de l&apos;épaule droite" currentOpTitle="Disjonction acromio claviculaire" />
          <Typography>
            Lors de l&apos;examen clinique, il est très important de rechercher d&apos;éventuelles
            complications associées : une ouverture ou menace cutanée, une atteinte
            vasculaire (prendre le pouls), une atteinte du plexus brachial et enfin
            des difficultés respiratoires faisant évoquer un pneumothorax
            (notamment en cas de fracture de côte associée).
          </Typography>
        </MainPart>

        <MainPart title="Quels sont les examens qui permettent de confirmer une disjonction acromio-claviculaire ?">
          <List>
            <List.Item>
              <Typography>
                Radiographies bilatérales et comparatives des articulations acromio-claviculaires :
                permettent de faire le diagnostic et de poser l&apos;indication
                chirurgicale selon les Stades de la
                {' '}
                <Typography variant="span" weight="bold">Classification de Rockwood</Typography>
                .
              </Typography>
            </List.Item>
          </List>
          <Figure imageName="figure3-disjonctionacromioclaviculaire.jpg" caption="Figure 3. Différents stades de la classification de Rockwood" currentOpTitle="Disjonction acromio claviculaire" />
          <List>
            <List.Item>
              <Typography>
                Un scanner avec des reconstructions osseuse en 3 dimensions
                peut être nécessaire en cas de fracture associée
                (fracture de la scapula, des côtes...)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                En cas de suspicion de lésion vasculaire, un scanner avec injection
                de produit de contraste (Angio-scanner) est prescrit.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                En cas de lésion neurologique associée, une IRM du plexus brachial et un
                électromyogramme (le plus souvent fait à J21) sont indispensables.
              </Typography>
            </List.Item>
          </List>
        </MainPart>

        <MainPart title="Quels sont les traitements médicaux et chirurgicaux en cas d'une disjonction acromio-claviculaire ?">
          <div>
            <Typography>
              <Typography variant="span" weight="bold">
                Le traitement non chirurgical
              </Typography>
              {' '}
              est indiqué dans
              {' '}
              <Typography variant="span" weight="bold">
                les stades 1, 2 et 3 de la Classification de Rockwood
              </Typography>
              . Le patient est informé de la possibilité d&apos;une gêne essentiellement
              esthétique séquellaire (petite saillie résiduelle de la clavicule).
            </Typography>
            <Typography>
              L&apos;épaule est immobilisée pour une durée de 2 à 3 semaines par un gilet
              orthopédique afin de favoriser la cicatrisation ligamentaire. Tout
              geste de réduction de la déformation lors de la mise en place de
              l&apos;immobilisation est à proscrire en raison de son inefficacité.
              La prise d&apos;antalgiques, d&apos;anti-inflammatoires non-stéroïdiens
              (7 jours maximum)
              et le glaçage de la clavicule (10 minutes, 3 fois par jour)
              permettent de diminuer la douleur.
            </Typography>
            <Typography>
              Quelques séances de rééducation sont en général
              nécessaires à l&apos;ablation du gilet orthopédique.
            </Typography>
          </div>
          <div>
            <Typography>
              <Typography variant="span" weight="bold">
                Le traitement chirurgical
              </Typography>
              {' '}
              est indiqué dans
              {' '}
              <Typography variant="span" weight="bold">
                les stades 4 et 5 de la Classification de Rockwood
              </Typography>
              {' '}
              en raison de la rupture de la chape delto-trapézienne. Les stades 3
              peuvent être opérés en cas de gêne esthétique importante
              ou chez les sportifs de haut niveau.
            </Typography>
            <Typography>
              Les disjonctions acromio-claviculaires aigües doivent être opérées dans les
              14 jours suivant le traumatisme afin de garantir une cicatrisation
              ligamentaire de qualité.
            </Typography>
            <Typography>
              L&apos;opération est réalisée sous anesthésie générale. Une anesthésie
              locorégionale (bloc inter-scalénique) peut y être associé
              afin de limiter les douleurs post-opératoires.
            </Typography>
            <Typography>
              La clavicule est stabilisée par la mise en place de deux boutons
              (1 sur la clavicule et 1 sous la coracoïde) reliés entre eux par
              deux fils hautement résistants. Cette intervention est réalisable
              sous arthroscopie (Figure 4).
            </Typography>
          </div>
          <FigureDouble imageNameOne="figure4-disjonctionacromioclaviculaire.jpg" imageNameTwo="figure4bis-disjonctionacromioclaviculaire.jpg" caption="Figure 4. Disjonction acromio-claviculaire réduite et stabilisée par le système de “DogBone" currentOpTitle="Disjonction acromio claviculaire" />
          <Typography>
            <Typography variant="span" weight="bold">
              Le patient est en général hospitalisé une nuit après l&apos;opération
            </Typography>
            .
            L&apos;immobilisation est réalisée à l&apos;aide d&apos;un
            gilet orthopédique pendant 6 semaines.
            La rééducation est débutée dès l&apos;ablation du gilet et la reprise du sport est
            autorisée à partir du 4ème mois post-opératoire.
          </Typography>
          <Typography>
            Dans les formes chroniques (au-delà de 14 jours),
            {' '}
            <Typography variant="span" weight="bold">
              une reconstruction ligamentaire
            </Typography>
            {' '}
            (ligamentoplastie) est conseillée. Différentes techniques de
            stabilisation ont été décrites
            dans la littérature pour ces formes : utilisation du ligament
            coraco-acromial (Weaver-Dunn),
            des tendons de la patte d&apos;oie qui sont prélevés au niveau du genou
            (droit interne, semi-tendineux) ...
          </Typography>
        </MainPart>

        <MainPart title="Quelles sont les possibles complications post-opératoires après une opération chirurgicale pour une disjonction acromio-claviculaire ?">
          <Typography>
            En cas de réalisation de l&apos;opération par un chirurgien
            spécialisé du membre supérieur,
            les risques de complication sont rares. Il faut néanmoins prendre en compte
            les complications suivantes :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">Complications liées à l&apos;anesthésie.</Typography>
            </List.Item>
            <List.Item>
              <Typography>
                <Typography variant="span" weight="bold">Lésions iatrogènes</Typography>
                {' '}
                plexus brachial, des vaisseaux sous-claviers et du poumon.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                <Typography variant="span" weight="bold">Démontage</Typography>
                {' '}
                en cas de mauvais positionnement des implants ou en
                cas d&apos;os de mauvaise qualité.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                <Typography variant="span" weight="bold">Infection</Typography>
                {' '}
                :
                rare en raison de l&apos;application des règles d&apos;hygiène et de
                l&apos;administration d&apos;une antibioprophylaxie pendant l&apos;opération.
                Le germe le plus souvent incriminé est le P.Acnès.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                <Typography variant="span" weight="bold">Détente secondaire du montage</Typography>
                {' '}
                :
                possible surtout en cas de non-respect des consignes post-opératoire.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                <Typography variant="span" weight="bold">Raideur de l&apos;épaule</Typography>
                {' '}
                :
                liée à l&apos;immobilisation post-opératoire. L&apos;évolution
                est le plus souvent favorable avec les séances de rééducation.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                <Typography variant="span" weight="bold">Capsulite rétractile (ou algodystrophie)</Typography>
                {' '}
                :
                se traduisant par des douleurs et un enraidissement de l&apos;épaule pouvant
                atteindre tout le membre supérieur. Il s&apos;agit d&apos;une complication non
                prévisible, pouvant durer 12 à 18 mois.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="epaule" />
  </Layout>
);

export default DisjonctionAcromioClaviculaire;
